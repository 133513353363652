<template>
  <div class="vue-ueditor-wrap">
    <vue-ueditor-wrap :editor-dependencies="editorDependencies" v-model="content" :config="editorConfig" :editor-id="editorId"></vue-ueditor-wrap>
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  // 可用组件的哈希表
  components: {
    VueUeditorWrap
  },
  // 接收传值
  props: {
    editorId: {
      type: String,
      default: 'editorId'
    },
    initContent: {
      type: String,
      default: ''
    }
  },
  // 数据对象
  data () {
    return {
      content: this.initContent,
      editorConfig: {
        headers: [
          {'Authorization1': 12211}
        ],
        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/UE/',
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: '//ueditor.szcloudplus.com/cos',
        // serverUrl: this.$store.state.uEditorServerUrl,
        serverUrl: this.$config.uEditorUrl,
        autoHeight: true,
        // imagePathFormat: '/files/{yyyy}{mm}{dd}-{time}{rand:6}',
        // imageUrlPrefix: 'http://localhost:8000'
        initialFrameWidth: '100%',

        initialFrameHeight: 300,
        uEditorServerUrl: this.$config.uEditorUrl,
        uploadImageRootUrl: this.$config.uEditorUrl,
        uploadXlsRootUrl: this.$config.uEditorUrl,
        uploadTemplateRootUrl: this.$config.uEditorUrl,
        rootUrl: this.$config.uEditorUrl
      },
      // 指定依赖的资源列表，下面的 JS 会依次加载，注意顺序。实际请求资源时会拼接上 UEDITOR_HOME_URL，当然你也可以填完整的 URL
      editorDependencies: [
        'ueditor.config.js',
        'ueditor.all.js'
      ]
    }
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    content(e){
      this.$emit('input', e)
    },
    initContent(e){
      this.content = e || ''
    }
  }
}
</script>

<style lang="less" scoped>
.vue-ueditor-wrap{
  line-height: 1 !important;
}
</style>
